{
    "ENV": "stg",
    "siteUrl": "https://stg.bobbob.io",
    "apiUrl": "https://stg.api.bobbob.io",
    "storageUrl": "https://stg.ipfs.bobbob.io",
    "awsOptionsPkce": {
        "awsCognitoClientId": "7l8pkf1c4bu9b8r5ahl5m9766j",
        "awsCognitoClientSecret": "",
        "awsCognitoClientCallbackUrl": "https://stg.bobbob.io/signin-cb-pkce",
        "awsCognitoClientLogoutUrl": "https://stg.bobbob.io/signout",
        "awsCognitoRedirectUri": "https://stg.bobbob.io/authenticate",
        "awsCognitoDomain": "stg.auth.bobbob.io",
        "awsCognitoJwksUrl": "https://cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_LVyE2phaB/.well-known/jwks.json",
        "awsCognitoRegion": "ap-southeast-2",
        "awsCognitoUserPoolId": "ap-southeast-2_LVyE2phaB"
    },
    "chainId": 11155111,
    "project": "bobbob"
}