/* eslint-disable no-console */
const { DateTime, Duration, Interval } = require('luxon');

function UtilsCommonMin() {
}
UtilsCommonMin.formatTimeHighRes = (timeMs) => {
    let pingLag;
    if (timeMs >= 60000) {
        pingLag = UtilsCommonMin.formatTimeUtil(timeMs, false);
    } else {
        pingLag = `${(timeMs / 1000).toFixed(1)}s`;
    }
    return pingLag;
};

UtilsCommonMin.formatTimeUtilFromMillisOld = (millisFromEpoch) => {
    const luxonNow = DateTime.now();
    const diff = millisFromEpoch - luxonNow.valueOf();
    // let expiresStr;
    // if (diff < 0) {
    //     expiresStr = UtilsCommonMin.formatTimeUtil(-diff);
    //     // TODO: after Math.floor in formatTimeUtil, could end up with 0, remove -ve if so as -0s looks weird (fixed in formatTimeUtil)
    //     expiresStr = `-${expiresStr}`;
    // } else {
    //     expiresStr = UtilsCommonMin.formatTimeUtil(diff);
    // }
    const expiresStr = UtilsCommonMin.formatTimeUtil(diff);
    return expiresStr;
};

UtilsCommonMin.formatTimeUtilFromMillisPast = (millisFromEpoch, luxonNowIn = undefined, includeFracSec = false) => {
    return UtilsCommonMin.formatTimeUtilFromMillisGeneric(millisFromEpoch, true, luxonNowIn, undefined, undefined, includeFracSec);
};
UtilsCommonMin.formatTimeUtilFromMillisFuture = (millisFromEpoch, luxonNowIn = undefined) => {
    return UtilsCommonMin.formatTimeUtilFromMillisGeneric(millisFromEpoch, false, luxonNowIn);
};
UtilsCommonMin.formatTimeUtilFromMillisGeneric = (
    millisFromEpoch,
    isPast = false,
    luxonNowIn = undefined,
    skipFlipIfNegative = false,
    absResult = false,
    includeFracSec = false,
) => {
    let luxonNow;
    if (luxonNowIn === undefined) {
        luxonNow = DateTime.now();
    } else {
        luxonNow = luxonNowIn;
    }
    let diff = millisFromEpoch - luxonNow.valueOf();
    const luxonDateTime = DateTime.fromMillis(millisFromEpoch);
    let luxonInterval;
    if (diff > 0) {
        luxonInterval = Interval.after(luxonDateTime, Duration.fromMillis(diff));
    } else {
        luxonInterval = Interval.before(luxonDateTime, Duration.fromMillis(-diff));
    }
    if (isPast) {
        diff *= -1;
    }
    // console.log(`luxonInterval [${JSON.stringify(luxonInterval)}]`);
    const expiresStr = UtilsCommonMin.formatTimeUtilByInterval(diff, luxonInterval, skipFlipIfNegative, absResult, includeFracSec);
    return expiresStr;
};

UtilsCommonMin.formatTimeUtilByInterval = (diffMsIn, luxonInterval, skipFlipIfNegative = false, absResult = false, includeFracSec = false) => {
    let diffMs = diffMsIn;
    let flippedNegative = false;
    if (diffMs < 0) {
        // this can happen if FE time is slower than BE
        // instead of throwing an error, we flip it to show correct duration
        // set to true only if caller is doing not critical logic, eg logging
        if (!skipFlipIfNegative) {
            diffMs *= -1;
            // console.log(`${window.logPrefix()}******* TIMESTAMP NOT IN-SYNC ******* timeMsIn [${timeMsIn}] is -ve, flipping to +ve`);
            flippedNegative = true;
        } else {
            // NO, throw error for now to handle upstream
            // make it argument for caller to decide.
            throw new Error(`Internal error: timeMsIn [${diffMsIn}] is -ve!`);
        }
    }
    let elapsedStr = '';
    const showWeeksAfter1Year = false;
    const showYearAfter1Year = true;

    const luxonDuration = luxonInterval.toDuration();
    const years = Number.parseInt(luxonDuration.toFormat('y'), 10);
    const weeks = Number.parseInt(luxonDuration.toFormat('w'), 10);
    if (showWeeksAfter1Year && weeks >= 52) {
        elapsedStr = luxonDuration.toFormat('w\'w\' d\'d\'');
        const zeroIndex = elapsedStr.indexOf(' 0d');
        if (zeroIndex > -1) {
            elapsedStr = elapsedStr.substring(0, zeroIndex);
        }
    } else if (showYearAfter1Year && years >= 1) {
        elapsedStr = luxonDuration.toFormat('y\'y\' d\'d\'');
        const zeroIndex = elapsedStr.indexOf(' 0d');
        if (zeroIndex > -1) {
            elapsedStr = elapsedStr.substring(0, zeroIndex);
        }
    } else {
        if (luxonDuration.toFormat('d') !== '0') {
            elapsedStr = luxonDuration.toFormat('d\'d\' h\'h\'');
            const zeroIndex = elapsedStr.indexOf(' 0h');
            if (zeroIndex > -1) {
                elapsedStr = elapsedStr.substring(0, zeroIndex);
            }
        } else {
            if (luxonDuration.toFormat('h') !== '0') {
                elapsedStr = luxonDuration.toFormat('h\'h\' m\'m\'');
                const zeroIndex = elapsedStr.indexOf(' 0m');
                if (zeroIndex > -1) {
                    elapsedStr = elapsedStr.substring(0, zeroIndex);
                }
            } else {
                if (luxonDuration.toFormat('m') !== '0') {
                    if (!includeFracSec) {
                        elapsedStr = luxonDuration.toFormat('m\'m\' s\'s\'');
                        const zeroIndex = elapsedStr.indexOf(' 0s');
                        if (zeroIndex > -1) {
                            elapsedStr = elapsedStr.substring(0, zeroIndex);
                        }
                    } else {
                        // elapsedStr = luxonDuration.toFormat('m\'m\' s.S\'s\'');
                        elapsedStr = luxonDuration.toFormat('m\'m\' s\'s\'S');
                        // milliseconds return 3 digits, trim to 1
                        const ms = elapsedStr.substring(elapsedStr.indexOf('s') + 1);
                        const ms1dp = ms.substring(0, 1);
                        elapsedStr = `${elapsedStr.substring(0, elapsedStr.indexOf('s'))}.${ms1dp}s`;
                        const zeroIndex = elapsedStr.indexOf(' 0.0s');
                        if (zeroIndex > -1) {
                            elapsedStr = elapsedStr.substring(0, zeroIndex);
                        }
                    }
                } else {
                    if (!includeFracSec) {
                        elapsedStr = luxonDuration.toFormat('s\'s\'');
                    } else {
                        // elapsedStr = luxonDuration.toFormat('s.S\'s\'');
                        elapsedStr = luxonDuration.toFormat('s\'s\'S');
                        // milliseconds return 3 digits, trim to 1
                        const ms = elapsedStr.substring(elapsedStr.indexOf('s') + 1);
                        const ms1dp = ms.substring(0, 1);
                        elapsedStr = `${elapsedStr.substring(0, elapsedStr.indexOf('s'))}.${ms1dp}s`;
                    }
                }
            }
        }
    }
    if (flippedNegative && !absResult) {
        if (!elapsedStr.startsWith('0')) {
            elapsedStr = `-${elapsedStr}`;
        }
    }
    return elapsedStr;
};

UtilsCommonMin.formatTimeUtil = (timeMsIn, includeSec, skipFlipIfNegative = false) => {
    let timeMs = timeMsIn;
    let flippedNegative = false;
    if (timeMs < 0) {
        // this can happen if FE time is slower than BE
        // instead of throwing an error, we flip it to show correct duration
        // set to true only if caller is doing not critical logic, eg logging
        if (!skipFlipIfNegative) {
            timeMs *= -1;
            // console.log(`${window.logPrefix()}******* TIMESTAMP NOT IN-SYNC ******* timeMsIn [${timeMsIn}] is -ve, flipping to +ve`);
            flippedNegative = true;
        } else {
            // NO, throw error for now to handle upstream
            // make it argument for caller to decide.
            throw new Error(`Internal error: timeMsIn [${timeMsIn}] is -ve!`);
        }
    }
    const useLuxon = false;
    const showYearAfter1Year = false;
    const showWeeksAfter1Year = false;
    const showFirst2 = true;
    let elapsedStr = '';
    if (useLuxon) {
        // duration has lost leap year information, cannot be used!
        const luxonDuration = Duration.fromMillis(timeMs);
        if (showFirst2) {
            const years = Number.parseInt(luxonDuration.toFormat('y'), 10);
            const weeks = Number.parseInt(luxonDuration.toFormat('w'), 10);
            if (showWeeksAfter1Year && weeks >= 52) {
                elapsedStr = luxonDuration.toFormat('w\'w\' d\'d\'');
                const zeroIndex = elapsedStr.indexOf(' 0d');
                if (zeroIndex > -1) {
                    elapsedStr = elapsedStr.substring(0, zeroIndex);
                }
            } else if (showYearAfter1Year && years >= 1) {
                elapsedStr = luxonDuration.toFormat('y\'y\' d\'d\'');
                const zeroIndex = elapsedStr.indexOf(' 0d');
                if (zeroIndex > -1) {
                    elapsedStr = elapsedStr.substring(0, zeroIndex);
                }
            } else {
                if (luxonDuration.toFormat('d') !== '0') {
                    elapsedStr = luxonDuration.toFormat('d\'d\' h\'h\'');
                    const zeroIndex = elapsedStr.indexOf(' 0h');
                    if (zeroIndex > -1) {
                        elapsedStr = elapsedStr.substring(0, zeroIndex);
                    }
                } else {
                    if (luxonDuration.toFormat('h') !== '0') {
                        elapsedStr = luxonDuration.toFormat('h\'h\' m\'m\'');
                        const zeroIndex = elapsedStr.indexOf(' 0m');
                        if (zeroIndex > -1) {
                            elapsedStr = elapsedStr.substring(0, zeroIndex);
                        }
                    } else {
                        if (luxonDuration.toFormat('m') !== '0') {
                            elapsedStr = luxonDuration.toFormat('m\'m\' s\'s\'');
                            const zeroIndex = elapsedStr.indexOf(' 0s');
                            if (zeroIndex > -1) {
                                elapsedStr = elapsedStr.substring(0, zeroIndex);
                            }
                        } else {
                            elapsedStr = luxonDuration.toFormat('s\'s\'');
                        }
                    }
                }
            }
        } else {
            throw new Error('Internal error: Unsupported flow');
        }
    } else {
        const weeks = Math.floor(timeMs / (1000 * 60 * 60 * 24 * 7));
        // NOTE: where are 52 weeks and 1 day in a leap year! So cannot show in Y D format with crude calculations below (which does not
        // take into account leap years)
        let days;
        if (showWeeksAfter1Year && weeks >= 52) {
            days = Math.floor((timeMs % (1000 * 60 * 60 * 24 * 7)) / (1000 * 60 * 60 * 24));
        } else {
            days = Math.floor(timeMs / (1000 * 60 * 60 * 24));
        }
        const hours = Math.floor((timeMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const mins = Math.floor((timeMs % (1000 * 60 * 60)) / (1000 * 60));
        const secs = Math.floor((timeMs % (1000 * 60)) / 1000);
        let weeksStr = `${weeks}`;
        let daysStr = `${days}`;
        let hoursStr = `${hours}`;
        let minsStr = `${mins}`;
        let secsStr = `${secs}`;
        const padStart = false;
        if (padStart) {
            weeksStr = weeksStr.padStart(2, '0');
            daysStr = daysStr.padStart(2, '0');
            hoursStr = hoursStr.padStart(2, '0');
            minsStr = minsStr.padStart(2, '0');
            secsStr = secsStr.padStart(2, '0');
        }
        if (showFirst2) {
            if (showWeeksAfter1Year && weeks >= 52) {
                elapsedStr = `${weeksStr}w`;
                if (days > 0) {
                    elapsedStr += ` ${daysStr}d`;
                }
            } else {
                if (days > 0) {
                    elapsedStr = `${daysStr}d`;
                    if (hours > 0) {
                        elapsedStr += ` ${hoursStr}h`;
                    }
                } else {
                    if (hours > 0) {
                        elapsedStr = `${hoursStr}h`;
                        if (mins > 0) {
                            elapsedStr += ` ${minsStr}m`;
                        }
                    } else {
                        if (mins > 0) {
                            elapsedStr = `${minsStr}m`;
                            if (secs > 0) {
                                elapsedStr += ` ${secsStr}s`;
                            }
                        } else {
                            elapsedStr = `${secsStr}s`;
                        }
                    }
                }
            }
        } else {
            // const elapsedStr = `[${daysStr}]d [${hoursStr}]h [${minsStr}]m [${secsStr}]s`;
            elapsedStr = `${daysStr}d ${hoursStr}h ${minsStr}m`;
            if (includeSec) {
                elapsedStr = `${elapsedStr} ${secsStr}s`;
            }
        }
    }
    if (flippedNegative) {
        if (!elapsedStr.startsWith('0')) {
            elapsedStr = `-${elapsedStr}`;
        }
    }
    return elapsedStr;
};

UtilsCommonMin.expiredCheckSetRemove = (expireDurationMs) => {
    const expired = true;
    let remove = false;
    const expireDurationMsFlip = expireDurationMs * -1;
    const secs = Math.floor((expireDurationMsFlip % (1000 * 60)) / 1000);
    // remove on the 6th second
    if (secs >= 6) {
        remove = true;
    }
    return {
        expired,
        remove,
    };
};

UtilsCommonMin.getTimeStr = (
    startsSec,
    startsLabel,
    closesSec,
    closesLabel,
    completedSec,
    completedLabel,
    cancelledSec,
    cancelledLabel,
    expiresSec,
    expiresLabel,
    expiredLabel,
    luxonNow,
    isPast = false,
) => {
    let results;
    let resultsMs;
    let expired = false;
    let remove = false;
    // give preference to completed over cancelled, as cancelled can be artifically cancelled
    // but completed will always come from on-chain
    if (completedSec) {
        const completedMs = Number.parseInt(completedSec, 10) * 1000;
        resultsMs = completedMs;
        const completedDurationMs = completedMs - luxonNow.valueOf();
        // const completedStr = UtilsCommonMin.formatTimeUtil(completedDurationMs);
        const completedStr = isPast ? UtilsCommonMin.formatTimeUtilFromMillisPast(completedMs) : UtilsCommonMin.formatTimeUtilFromMillisFuture(completedMs);
        results = `${completedLabel ? (completedLabel === '-' ? '' : completedLabel) : 'A: '}${completedStr}`;
        ({
            expired,
            remove,
        } = UtilsCommonMin.expiredCheckSetRemove(completedDurationMs));
    } else if (cancelledSec) {
        const cancelledMs = Number.parseInt(cancelledSec, 10) * 1000;
        resultsMs = cancelledMs;
        const cancelledDurationMs = cancelledMs - luxonNow.valueOf();
        // const cancelledStr = UtilsCommonMin.formatTimeUtil(cancelledDurationMs);
        const cancelledStr = isPast ? UtilsCommonMin.formatTimeUtilFromMillisPast(cancelledMs) : UtilsCommonMin.formatTimeUtilFromMillisFuture(cancelledMs);
        results = `${cancelledLabel ? (cancelledLabel === '-' ? '' : cancelledLabel) : 'C: '}${cancelledStr}`;
        ({
            expired,
            remove,
        } = UtilsCommonMin.expiredCheckSetRemove(cancelledDurationMs));
    } else {
        let startsMs = 0;
        // let startsTsStr = '';
        if (startsSec) {
            startsMs = Number.parseInt(startsSec, 10) * 1000;
            // startsTsStr = DateTime.fromMillis(startsMs).toFormat(bobbob.ConstantsCommon.DATE_TIME_FORMAT_NO_SEC);
        }
        let closesMs = 0;
        // let closesTsStr = '';
        if (closesSec) {
            closesMs = Number.parseInt(closesSec, 10) * 1000;
            // closesTsStr = DateTime.fromMillis(closesMs).toFormat(bobbob.ConstantsCommon.DATE_TIME_FORMAT_NO_SEC);
        }

        const startDurationMs = startsMs - luxonNow.valueOf();
        if (startDurationMs >= 0) {
            // if (timeMs > 0) {
            // const startsStr = UtilsCommonMin.formatTimeUtil(startDurationMs);
            const startsStr = isPast ? UtilsCommonMin.formatTimeUtilFromMillisPast(startsMs) : UtilsCommonMin.formatTimeUtilFromMillisFuture(startsMs);
            results = `${startsLabel || ''}${startsStr}`;
            resultsMs = startsMs;
            // } else {
            //     $(elem).html(`${startsLabel ? startsLabel : ''}! STARTED !`);
            // }
        } else {
            const closeDurationMs = closesMs - luxonNow.valueOf();
            if (closeDurationMs >= 0) {
                // const closesStr = UtilsCommonMin.formatTimeUtil(closeDurationMs);
                const closesStr = isPast ? UtilsCommonMin.formatTimeUtilFromMillisPast(closesMs) : UtilsCommonMin.formatTimeUtilFromMillisFuture(closesMs);
                results = `${closesLabel || ''}${closesStr}`;
                resultsMs = closesMs;
            } else {
                const expiresMs = Number.parseInt(expiresSec, 10) * 1000;
                resultsMs = expiresMs;
                const expireDurationMs = expiresMs - luxonNow.valueOf();
                // const expiresStr = UtilsCommonMin.formatTimeUtil(expireDurationMs);
                const expiresStr = isPast ? UtilsCommonMin.formatTimeUtilFromMillisPast(expiresMs) : UtilsCommonMin.formatTimeUtilFromMillisFuture(expiresMs);
                if (expireDurationMs >= 0) {
                    results = `${expiresLabel || ''}${expiresStr}`;
                } else {
                    // results = `${expiredLabel ? (expiredLabel === '-' ? '' : expiredLabel) : '! EXPIRED ! '}${expiredStr} ago`;
                    results = `${expiredLabel ? (expiredLabel === '-' ? '' : expiredLabel) : 'E: '}${expiresStr}`;
                    ({
                        expired,
                        remove,
                    } = UtilsCommonMin.expiredCheckSetRemove(expireDurationMs));
                }
            }
        }
    }
    return {
        label: results,
        timeMs: resultsMs,
        expired,
        remove,
    };
};

UtilsCommonMin.getExpiryStr = (expiresSec, expiresLabel, expiredLabel, luxonNow) => {
    let results;
    let expired = false;
    let remove = false;
    const expiresMs = Number.parseInt(expiresSec, 10) * 1000;
    const resultsMs = expiresMs;
    const expireDurationMs = expiresMs - luxonNow.valueOf();
    // const expiresStr = UtilsCommonMin.formatTimeUtil(expireDurationMs);
    const expiresStr = UtilsCommonMin.formatTimeUtilFromMillisFuture(expiresMs);
    if (expireDurationMs >= 0) {
        results = `${expiresLabel || ''}${expiresStr}`;
    } else {
        // results = `${expiredLabel ? (expiredLabel === '-' ? '' : expiredLabel) : '! EXPIRED ! '}${expiredStr} ago`;
        results = `${expiredLabel ? (expiredLabel === '-' ? '' : expiredLabel) : 'E: '}${expiresStr}`;
        ({
            expired,
            remove,
        } = UtilsCommonMin.expiredCheckSetRemove(expireDurationMs));
        // expired = true;
        // const expireDurationMsFlip = expireDurationMs * -1;
        // const secs = Math.floor((expireDurationMsFlip % (1000 * 60)) / 1000);
        // // remove on the 6th second
        // if (secs >= 6) {
        //     remove = true;
        // }
    }
    return {
        label: results,
        timeMs: resultsMs,
        expired,
        remove,
    };
};

UtilsCommonMin.checkBool = (input) => {
    if (input === undefined) {
        throw new Error('Internal error: input for bool is undefined!');
    }
    return input;
};

UtilsCommonMin.getRsvFromSignature = (web3, signature) => {
    const signatureNo0x = web3.utils.stripHexPrefix(signature);
    const r = `0x${signatureNo0x.substring(0, 64)}`;
    const s = `0x${signatureNo0x.substring(64, 128)}`;
    const v = Number.parseInt(signatureNo0x.substring(128, 130), 16);
    return {
        r,
        s,
        // v: `${v}`,
        v,
    };
};

UtilsCommonMin.getSignatureFromRsv = (web3, rsv) => {
    const rNo0x = web3.utils.stripHexPrefix(rsv.r).padStart(64, '0');
    const sNo0x = web3.utils.stripHexPrefix(rsv.s).padStart(64, '0');
    const vHex = web3.utils.toHex(rsv.v);
    const vNo0x = web3.utils.stripHexPrefix(vHex).padStart(2, '0');
    // if (vNo0x.length < 2) {
    //     vNo0x = `0${vNo0x}`;
    // }
    const signature = `0x${rNo0x}${sNo0x}${vNo0x}`;
    return signature;
};

UtilsCommonMin.getSignatureFromRsvLedgerSignTxn = (rsv) => {
    const signature = `0x${rsv.r.padStart(64, '0')}${rsv.s.padStart(64, '0')}${rsv.v.padStart(2, '0')}`;
    return signature;
};

UtilsCommonMin.getSignatureFromRsvLedgerSignMsg = (rsv) => {
    let v = rsv.v - 27;
    // let v = rsv.v;
    v = v.toString(16).padStart(2, '0');
    // if (v.length < 2) {
    //     v = `0${v}`;
    // }
    const signature = `0x${rsv.r.padStart(64, '0')}${rsv.s.padStart(64, '0')}${v}`;
    return signature;
};

UtilsCommonMin.bufferToBase64URLString = (buffer) => {
    const bytes = new Uint8Array(buffer);
    let str = '';

    for (const charCode of bytes) {
        str += String.fromCharCode(charCode);
    }

    const base64String = btoa(str);

    return base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
};

/**
   * Convert from a Base64URL-encoded string to an Array Buffer. Best used when converting a
   * credential ID from a JSON string to an ArrayBuffer, like in allowCredentials or
   * excludeCredentials
   *
   * Helper method to compliment `bufferToBase64URLString`
   *
   * @param {string} base64URLString
   * @returns {ArrayBuffer}
   */
UtilsCommonMin.base64URLStringToBuffer = (base64URLString) => {
    // Convert from Base64URL to Base64
    const base64 = base64URLString.replace(/-/g, '+').replace(/_/g, '/');
    /**
     * Pad with '=' until it's a multiple of four
     * (4 - (85 % 4 = 1) = 3) % 4 = 3 padding
     * (4 - (86 % 4 = 2) = 2) % 4 = 2 padding
     * (4 - (87 % 4 = 3) = 1) % 4 = 1 padding
     * (4 - (88 % 4 = 0) = 4) % 4 = 0 padding
     */
    const padLength = (4 - (base64.length % 4)) % 4;
    const padded = base64.padEnd(base64.length + padLength, '=');

    // Convert to a binary string
    const binary = atob(padded);

    // Convert binary string to buffer
    const buffer = new ArrayBuffer(binary.length);
    const bytes = new Uint8Array(buffer);

    for (let i = 0; i < binary.length; i++) {
        bytes[i] = binary.charCodeAt(i);
    }

    return buffer;
};

UtilsCommonMin.getRandomBytes = (length = 16) => {
    const arrayBuffer = new Uint8Array(Array.from({ length }));
    return crypto.getRandomValues(arrayBuffer);
};

// `salt` can be static for your site or unique per credential depending on your needs.
// crypto.getRandomValues(new Uint8Array(new Array(32)));
UtilsCommonMin.firstSalt = new Uint8Array([
    0x4a, 0x18, 0xa1, 0xe7, 0x4b, 0xfb, 0x3d, 0x3f, 0x2a, 0x5d, 0x1f, 0x0c,
    0xcc, 0xe3, 0x96, 0x5e, 0x00, 0x61, 0xd1, 0x20, 0x82, 0xdc, 0x2a, 0x65,
    0x8a, 0x18, 0x10, 0xc0, 0x0f, 0x26, 0xbe, 0x1e,
]).buffer;

UtilsCommonMin.deriveEncryptionKey = async (inputKeyMaterial) => {
    const textEncoder = new TextEncoder();
    const keyDerivationKey = await crypto.subtle.importKey(
        'raw',
        inputKeyMaterial,
        'HKDF',
        false,
        ['deriveKey'],
    );

    // Never forget what you set this value to or the key can't be
    // derived later
    const label = 'BOBBOB encryption key';
    const info = textEncoder.encode(label);
    // `salt` is a required argument for `deriveKey()`, but should
    // be empty
    const salt = new Uint8Array();

    const encryptionKey = await crypto.subtle.deriveKey(
        {
            name: 'HKDF', info, salt, hash: 'SHA-256',
        },
        keyDerivationKey,
        { name: 'AES-GCM', length: 256 },
        // No need for exportability because we can deterministically
        // recreate this key
        false,
        ['encrypt', 'decrypt'],
    );

    return encryptionKey;
};

UtilsCommonMin.aesEncryptByTextKey = async (encryptionKeyText, dataText) => {
    const textEncoder = new TextEncoder();
    // const encryptionKey = this.web3.utils.keccak256(encryptionKeyText);
    // const encryptionKeyNoHex = this.web3.utils.stripHexPrefix(encryptionKey);
    const encryptionKeyArr = textEncoder.encode(encryptionKeyText);
    // const encryptionKeyBuffer = Buffer.from(encryptionKeyNoHex, 'hex');
    const encryptionKeyBuffer = await crypto.subtle.digest('SHA-256', encryptionKeyArr);
    const encryptionKeyKey = await crypto.subtle.importKey(
        'raw',
        // Buffer.from(encryptionKeyNoHex, 'hex'),
        encryptionKeyBuffer,
        {
          name: 'AES-GCM',
          length: 256,
        },
        false,
        ['encrypt', 'decrypt']
    );
    return UtilsCommonMin.aesEncrypt(encryptionKeyKey, dataText);
};

UtilsCommonMin.aesEncrypt = async (encryptionKeyKey, dataText) => {
    const nonceBuffer = crypto.getRandomValues(new Uint8Array(12));
    const nonce = UtilsCommonMin.bufferToBase64URLString(nonceBuffer);
    // const encryptionKey = this.web3.utils.keccak256(encryptionKeyText);
    // const encryptedAccountEthStr = AES.encrypt(dataText, encryptionKey).toString();
    const textEncoder = new TextEncoder();
    const encryptedBuffer = await crypto.subtle.encrypt(
        { name: 'AES-GCM', iv: nonceBuffer },
        encryptionKeyKey,
        textEncoder.encode(dataText),
    );
    const encryptedData = UtilsCommonMin.bufferToBase64URLString(encryptedBuffer);
    return {
        encryptedData,
        nonce,
    };
};

UtilsCommonMin.aesDecryptByTextKey = async (encryptionKeyText, encryptedData, nonce) => {
    const textEncoder = new TextEncoder();
    // const encryptionKey = this.web3.utils.keccak256(encryptionKeyText);
    // const encryptionKeyNoHex = this.web3.utils.stripHexPrefix(encryptionKey);
    const encryptionKeyArr = textEncoder.encode(encryptionKeyText);
    // const encryptionKeyBuffer = Buffer.from(encryptionKeyNoHex, 'hex');
    const encryptionKeyBuffer = await crypto.subtle.digest('SHA-256', encryptionKeyArr);
    const encryptionKeyKey = await crypto.subtle.importKey(
        'raw',
        // Buffer.from(encryptionKeyNoHex, 'hex'),
        encryptionKeyBuffer,
        {
          name: 'AES-GCM',
          length: 256,
        },
        false,
        ['encrypt', 'decrypt']
    );
    return UtilsCommonMin.aesDecrypt(encryptionKeyKey, encryptedData, nonce);
};

UtilsCommonMin.aesDecrypt = async (encryptionKeyKey, encryptedData, nonce) => {
    // const encryptionKey = this.web3.utils.keccak256(encryptionKeyText);
    // const decryptedData = AES.decrypt(encryptedData, encryptionKey).toString(ENC_UTF8);
    const decryptedBuffer = await crypto.subtle.decrypt(
        { name: 'AES-GCM', iv: UtilsCommonMin.base64URLStringToBuffer(nonce) },
        encryptionKeyKey,
        UtilsCommonMin.base64URLStringToBuffer(encryptedData),
    );
    const textDecoder = new TextDecoder();
    const decryptedData = textDecoder.decode(decryptedBuffer);
    return decryptedData;
};

UtilsCommonMin.generateEncryptionKeyEncoded = async () => {
    const encryptionKey = await crypto.subtle.generateKey(
        { name: 'AES-GCM', length: 256 },
        true,
        ['encrypt', 'decrypt'],
    );
    const encryptionKeyJwk = await crypto.subtle.exportKey('jwk', encryptionKey);
    const encryptionKeyJwkStr = JSON.stringify(encryptionKeyJwk);
    const textEncoder = new TextEncoder();
    const encryptionKeyEncoded = textEncoder.encode(encryptionKeyJwkStr);
    return encryptionKeyEncoded;
};

UtilsCommonMin.importEncryptionKeyEncoded = async (encryptionKeyEncoded) => {
    const textDecoder = new TextDecoder();
    const encryptionKeyJwkStr = textDecoder.decode(encryptionKeyEncoded);
    const encryptionKeyJwk = JSON.parse(encryptionKeyJwkStr);
    const encryptionKey = await crypto.subtle.importKey(
        'jwk',
        encryptionKeyJwk,
        { name: 'AES-GCM' },
        false,
        ['encrypt', 'decrypt'],
    );
    return encryptionKey;
};

module.exports = UtilsCommonMin;
