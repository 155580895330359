{
    "ENV": "prod",
    "siteUrl": "https://bobbob.io",
    "apiUrl": "https://api.bobbob.io",
    "storageUrl": "https://ipfs.bobbob.io",
    "awsOptionsPkce": {
        "awsCognitoClientId": "6lr4jnopvq7uuindppacjvqmv7",
        "awsCognitoClientSecret": "",
        "awsCognitoClientCallbackUrl": "https://bobbob.io/signin-cb-pkce",
        "awsCognitoClientLogoutUrl": "https://bobbob.io/signout",
        "awsCognitoRedirectUri": "https://bobbob.io/authenticate",
        "awsCognitoDomain": "prod.auth.bobbob.io",
        "awsCognitoJwksUrl": "https://cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_35LerGKZ0/.well-known/jwks.json",
        "awsCognitoRegion": "ap-southeast-2",
        "awsCognitoUserPoolId": "ap-southeast-2_35LerGKZ0"
    },
    "chainId": 1,
    "project": "bobbob"
}